import type { MediaItemInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/Media.vue';
import type { ICustomHtmlFields } from '~/lib/ContentfulService';

export function generateIframeObject(
  iFrameFields: ICustomHtmlFields
): MediaItemInterface {
  return {
    type: 'iframe',
    content: {
      url: iFrameFields.iFrameUrl,
      aspectRatio: iFrameFields.aspectRatio ?? '4:3',
      html: iFrameFields.html,
      width: iFrameFields.iFrameWidth ?? -1,
      height: iFrameFields.iFrameHeight ?? -1
    }
  };
}
